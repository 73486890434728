.truncated-wrapper {
    background-color: inherit;
    display: inline-block;
    position: relative;

    .truncated,
    .initial-text,
    .show-initial {
        transition: opacity 0.25s ease;
    }

    .truncated,
    .initial-text {
        background-color: inherit;
        display: inline-block;
        opacity: 1;
        z-index: 1;
    }

    .show-initial {
        background-color: inherit;
        display: block;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        white-space: nowrap;
        width: auto;
        z-index: 2;
    }

    &.was-truncated {
        &:hover,
        &:focus {
            .truncated,
            .initial-text {
                opacity: 0;
            }

            .show-initial {
                opacity: 1;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .truncated-wrapper {
        &.was-truncated {
            .truncated,
            .initial-text {
                display: none;
                opacity: 0;
            }

            .show-initial {
                opacity: 1;
                position: relative;
                white-space: normal;
            }
        }
    }
}
