@use '../../../sass/variables';

.ngp-breadcrumb {
    background-color: variables.$breadCrumbBackgroundColor;
    color: variables.$breadCrumbTextColor;
    padding: 0 15px;

    .breadcrumb-wrapper {
        background-color: inherit;
        padding: 0 15px 0 162px;

        & > div {
            background-color: inherit;
        }
    }

    .breadcrumb {
        background-color: inherit;
        margin-bottom: 0;
        padding: 10px 15px;

        & > li {
            background-color: inherit;
            font-size: 14px;
            padding: 0 30px 0 0;
            position: relative;

            &:first-of-type {
                padding-left: 0;
            }

            &::before,
            &::after {
                background-color: variables.$breadCrumbDividerBackgroundColorAlt;
                content: '';
                display: block;
                height: 10px;
                padding: 0;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: rotate(30deg);
                transform-origin: center top;
                width: 1px;
            }

            &::after {
                transform: rotate(150deg);
            }

            a {
                background-color: inherit;
                color: variables.$breadCrumbTextColor;
                text-decoration: none;

                &:hover,
                :focus {
                    color: darken(variables.$blue, 20%);
                }
            }

            &:last-of-type {
                padding-right: 0;

                &::before,
                &::after {
                    display: none !important;
                }
            }
        }

        & > li + li {
            &::before {
                background-color: variables.$breadCrumbDividerBackgroundColorAlt;
                width: 1px;
            }
        }
    }
}

.ngp-breadcrumb-hide {
    display: none;
  }

@media only screen and (max-width: 1024px) {
    .ngp-breadcrumb {
        width: 100%;

        .breadcrumb-wrapper {
            padding-left: 15px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .ngp-breadcrumb {
        padding-left: 0;
        padding-right: 0;

        .breadcrumb-wrapper {
            padding: 0 15px;
        }

        .breadcrumb {
            padding: 10px 0;

            & > li {
                display: none;

                &:first-of-type,
                &:last-of-type {
                    display: inline-block;
                }
            }
        }
    }
}
